import { useState, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import OpenAiLogo from "../components/icons/OpenAiLogo.js";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext.js";
import FeatureSection from "../components/landing/FeatureSection.js";
import ContentSection from "../components/landing/ContentSection.js";
import Pricing from "../components/landing/Pricing.js";
import Newsletter from "../components/landing/Newsletter.js";
import JsongenLogo from "../components/icons/JsongenLogo.js";

const navigation = [
  { name: "Features", href: "#features" },
  { name: "Pricing", href: "#pricing" },
  { name: "API Reference", url: `${process.env.REACT_APP_CLIENT_BASE_URL}/docs/api` },
];

export default function LandingPage() {
  const { session } = useAuth();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const productRef = useRef(null);
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);
  const newsletterRef = useRef(null);

  const handleNav = (item) => {
    const sectionRefs = {
      "#features": featuresRef,
      "#pricing": pricingRef,
      "#newsletter": newsletterRef,
    };

    const ref = sectionRefs[item.href];

    if (!item.href) {
      window.open(item.url, "_blank");
    } else if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
    setMobileMenuOpen(false);
  };

  return (
    <div className="bg-white">
      {/* Header */}
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <JsongenLogo textColor="text-indigo-700" braceColor="text-gray-600" />
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <button
                key={item.name}
                onClick={() => handleNav(item)}
                className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer"
              >
                {item.name}
              </button>
            ))}
          </div>
          {/* 
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {session ? (
              <Link
                to="/dashboard"
                className="leading-6 rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Dashboard
              </Link>
            ) : (
              <Link to="/signin" className="text-sm font-semibold leading-6 text-gray-900">
                Log in <span aria-hidden="true">&rarr;</span>
              </Link>
            )}
          </div>
          */}
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <JsongenLogo small braceColor="text-indigo-700" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <button
                      key={item.name}
                      onClick={() => handleNav(item)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 w-full text-left"
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
                <div className="py-6">
                  {session ? (
                    <Link
                      to="/dashboard"
                      className="leading-6 rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Dashboard
                    </Link>
                  ) : (
                    <Link to="/signin" className="text-sm font-semibold leading-6 text-gray-900">
                      Log in <span aria-hidden="true">&rarr;</span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <main className="isolate">
        {/* Hero section */}
        <div ref={productRef} className="relative pt-14">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="pt-24 md:py-28">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl md:text-6xl">
                  One single endpoint,
                  <br />
                  endless opportunities
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600 flex flex-col items-center justify-center">
                  <span>
                    Generate JSON reliably by extracting file contents or tailoring tasks by
                    prompting your needs. Just define your output structure.
                  </span>
                  <span className="text-black flex items-center justify-center">
                    <span>Powered by</span>
                    <OpenAiLogo />
                  </span>

                  <span className="sr-only">OpenAI</span>
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  {/*}
                  <Link
                    to="/signup"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Go to Playground
                  </Link>
                  */}
                  <button
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => handleNav(navigation[0])}
                  >
                    Learn more <span aria-hidden="true">→</span>
                  </button>
                </div>
              </div>
              <div className="mt-16 flow-root sm:mt-24">
                <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                  <img
                    src="https://fpbtoiiubqwluapvkrhs.supabase.co/storage/v1/object/public/jsongen_public/test/jsongen-dashboard.png?t=2024-09-14T23%3A48%3A16.931Z"
                    alt="App screenshot"
                    width={2432}
                    height={1442}
                    className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>

        {/* Feature section */}
        <div ref={featuresRef} className="mx-auto pt-24 mb-24 max-w-7xl px-6 lg:px-8">
          <FeatureSection />
        </div>
        {/* Service Description section */}
        <ContentSection />

        {/* Pricing section */}
        <div ref={pricingRef}>
          <Pricing />
        </div>

        {/* Newsletter section */}
        <div ref={newsletterRef}>
          <Newsletter />
        </div>
      </main>
    </div>
  );
}
