import React, { Suspense, lazy } from "react"; // Added Suspense and lazy
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext.js";
import ProtectedRoute from "./components/auth/ProtectedRoute.js";
import AuthPage from "./pages/AuthPage.js";
import ForgotPasswordPage from "./pages/ForgotPasswordPage.js";
import LandingPage from "./pages/LandingPage.js";
import NotFoundPage from "./pages/NotFoundPage.js";
import TestPage from "./pages/TestPage.js";
import LoadingSpinner from "./components/ui/LoadingSpinner.js";

// Lazy load the pages
const DashboardPage = lazy(() => import("./pages/DashboardPage.js"));
const SwaggerUIComponent = lazy(() => import("./components/reference/SwaggerUi.js"));

function App() {
  return (
    <Router>
      <div className="App">
        <AuthProvider>
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/test" element={<TestPage />} />
              {/*
              <Route path="/signin" element={<AuthPage type="SIGNIN" />} />
              <Route path="/signup" element={<AuthPage type="SIGNUP" />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password" element={<ForgotPasswordPage />} />
              */}
              <Route path="/docs/api" element={<SwaggerUIComponent />} />
              <Route
                path="/dashboard/:selectedItem?"
                element={
                  <ProtectedRoute redirectTo="/signin">
                    <DashboardPage />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </AuthProvider>
      </div>
    </Router>
  );
}

export default App;
